import { FC } from 'react';
import IconProps from './IconProps';

const StarIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8322 5.56297C11.0761 4.81234 12.138 4.81234 12.382 5.56297L13.6192 9.37083C13.7282 9.70651 14.0411 9.93379 14.394 9.93379H18.3979C19.1871 9.93379 19.5153 10.9437 18.8768 11.4076L15.6376 13.7611C15.352 13.9685 15.2326 14.3363 15.3417 14.6719L16.5789 18.4798C16.8228 19.2304 15.9636 19.8546 15.3251 19.3907L12.086 17.0373C11.8004 16.8298 11.4137 16.8298 11.1282 17.0373L7.88903 19.3907C7.25052 19.8546 6.3914 19.2304 6.63529 18.4798L7.87254 14.6719C7.98161 14.3363 7.86212 13.9685 7.57657 13.7611L4.33741 11.4076C3.69889 10.9437 4.02705 9.93379 4.81629 9.93379H8.82012C9.17308 9.93379 9.4859 9.70651 9.59497 9.37083L10.8322 5.56297Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
